// LoadingPopup.js
import React from "react";
import "./LoadingPopup.css";

const LoadingPopup = ({
  isBills = false,
}) => {
  return (
    <div className="loading-overlay">
      <div className="loading-popup">
        <svg viewBox="25 25 50 50">
          <circle r="20" cy="50" cx="50"></circle>
        </svg>
        <p>{isBills ? "Analyse des factures en cours" : "Analyse du contrat en cours"}</p>
      </div>
    </div>
  );
};

export default LoadingPopup;
