import React, { useEffect, useState } from 'react';
import './Notification.css';

const Notification = ({ notifications, onClose }) => {
  const [topPosition, setTopPosition] = useState(100);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setTopPosition(100 + scrollPosition);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [visibleNotifications, setVisibleNotifications] = useState(notifications);

  useEffect(() => {
    setVisibleNotifications(notifications);
  }, [notifications]);

  const handleClose = (index) => {
    const updatedNotifications = visibleNotifications.map((notification, i) => 
      i === index ? { ...notification, closing: true } : notification
    );

    setVisibleNotifications(updatedNotifications);

    setTimeout(() => {
      onClose(index);
    }, 300);
  };

  return (
    <div className="notifications-container" style={{ top: `${topPosition}px` }}>
      {visibleNotifications.map((notification, index) => (
        <div
          key={index}
          className={`notification ${notification.closing ? 'notification-hide' : 'notification-show'} notification-${notification.type}`}
        >
          <span className="notification-message">{notification.message}</span>
          <button className="notification-close" onClick={() => handleClose(index)}>
            &times;
          </button>
        </div>
      ))}
    </div>
  );
};

export default Notification;
