import React, { useState, useEffect } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const CircularProgressBar = ({ percentage }) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setValue(percentage);
    }, 500); // Start the animation after a delay

    return () => clearTimeout(timeout);
  }, [percentage]);

  return (
    <div style={{ width: "200px", height: "200px", position: "relative" }}>
      <CircularProgressbar
        value={value}
        styles={buildStyles({
          pathColor: "#5E63B6",
          textColor: "#5E63B6",
          trailColor: "#d6d6d6",
          textSize: "12px",
        })}
      />
      <div
        className="progressbar-text"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
          color: "#5E63B6",
          fontSize: "20px",
          fontWeight: "bold",
        }}
      >
        <span>{percentage}%</span>
        <br />
        <span>de couverture</span>
      </div>
    </div>
  );
};

export default CircularProgressBar;
