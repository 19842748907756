import React from "react";
import "./CoverageRecap.css";

const CoverageRecap = () => {
  return (
    <div id="first-section-landing-container">
      <div id="first-section-landing-infos">
        <div id="brandname-coverage-home">Coverage</div>
        <div id="brandslogan-coverage-home">Assurrez-vous d'être couvert</div>
        <div id="coverage-recap-home">
          Rationalisez vos contrats d'assurance en un seul clic pour une
          meilleure compréhension de leurs limites. Stockez vos contrats et
          leurs versions antérieures de manière sécurisée. Associés à toutes les
          factures de votre domicile, consultez en temps réel votre taux de
          couverture total.
        </div>
        <div id="container-dwl-buttons-welcome-page">
          <img
            src="get-app-apple-button.svg"
            alt=""
            className="button-dwl-app"
          />
          <img
            src="get-app-google-button.svg"
            alt=""
            className="button-dwl-app"
          />
        </div>
      </div>
      <div id="first-section-landing-mockup-container">
        <img
          src="Mokup-coverage-first.svg"
          alt=""
          id="first-section-landing-mockup"
        />
      </div>
    </div>
  );
};

export default CoverageRecap;
