import React, { useState } from "react";
import "./RegisterBeta.css";

const RegisterBeta = () => {
  const [email, setEmail] = useState("");
  const [domain, setDomain] = useState("");
  const [status, setStatus] = useState("");
  const [emailError, setEmailError] = useState("");
  const [showErrors, setShowErrors] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value)) {
      setEmailError("Veuillez entrer un email valide.");
    } else {
      setEmailError("");
    }
  };

  const handleDomainChange = (e) => {
    setDomain(e.target.value);
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (emailError || !email || !status || (isDomainRequired() && !domain)) {
      setShowErrors(true);
      return;
    }
    alert("Inscription réussie !");
    // Ici vous pouvez ajouter la logique pour envoyer les données au backend
  };

  const isDomainRequired = () => {
    return (
      status === "employed" || status === "student" || status === "retired"
    );
  };

  const renderDomainInputLabel = () => {
    switch (status) {
      case "employed":
        return "Domaine de travail";
      case "student":
        return "Domaine d'étude";
      case "retired":
        return "Ancien domaine de travail";
      default:
        return "";
    }
  };

  const isFormValid = () => {
    if (emailError || !email || !status) {
      return false;
    }

    if (isDomainRequired()) {
      return domain !== "";
    }

    return true;
  };

  return (
    <div className="form-container">
      <form className="beta-signup-form" onSubmit={handleSubmit}>
        <h2>Inscription à la Beta</h2>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={handleEmailChange}
            className={`${emailError || (showErrors && !email) ? "error" : ""}`}
            required
          />
          {emailError && <span className="error-message">{emailError}</span>}
        </div>
        {(status === "employed" ||
          status === "student" ||
          status === "retired") && (
          <div className="form-group">
            <label htmlFor="domain">{renderDomainInputLabel()}:</label>
            <input
              type="text"
              id="domain"
              value={domain}
              onChange={handleDomainChange}
              className={`${showErrors && !domain ? "error" : ""}`}
              required
            />
          </div>
        )}
        <div className="form-group">
          <label htmlFor="status">Statut:</label>
          <select
            id="status"
            value={status}
            onChange={handleStatusChange}
            className={`${showErrors && !status ? "error" : ""}`}
            required
          >
            <option value="" disabled hidden>
              Sélectionnez votre statut
            </option>
            <option value="employed">Employé/Cadre</option>
            <option value="student">En études</option>
            <option value="unemployed">Sans emploi</option>
            <option value="retired">Retraité</option>
          </select>
        </div>
        <button
          type="submit"
          className="submit-button"
          disabled={!isFormValid()}
        >
          S'inscrire à la Bêta
        </button>
        {showErrors && !isFormValid() && (
          <div className="error-message global-error">
            Veuillez remplir tous les champs obligatoires.
          </div>
        )}
      </form>
    </div>
  );
};

export default RegisterBeta;
