import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import LocauxDisplayAdd from "../LocauxDisplayAdd/LocauxDisplayAdd";
import LocauxElem from "../LocauxElem/LocauxElem";
import BillsContrats from "../BillsContrats/BillsContrats";
import LocalPopupContent from "../LocalPopup/LocalPopupContent";
import UniversalModal from "../UniversalModal/UniversalModal";
import "./Home.css";
import BillsDisplay from "../BillsDisplay/BillsDisplay";
import ContractDisplay from "../ContractDisplay/ContractDisplay";

const Home = ({ openModalForTuto, pauseJoyride, resumeJoyride }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [editable, setEditable] = React.useState(false);
  const [selectedLocal, setSelectedLocal] = React.useState({});
  const [locaux, setLocaux] = React.useState([]);
  const location = useLocation();
  const { localId } = location.state || {};
  const [localRequests, setLocalRequests] = React.useState([]);
  const [selectedRequest, setSelectedRequest] = React.useState(false);
  const [selectedBill, setSelectedBill] = React.useState(null);
  const [viewBill, setViewBill] = React.useState(false);
  const [selectedContract, setSelectedContract] = React.useState(null);
  const [viewContract, setViewContract] = React.useState(false);
  const [filteredBills, setFilteredBills] = React.useState([]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const seeBill = (bill, filteredBills) => {
    setSelectedBill(bill);
    console.log(filteredBills);
    setFilteredBills(filteredBills);
    setViewBill(true);
    window.scrollTo(0, 0);
  };
  const seeContract = (contract) => {
    setSelectedBill(contract);
    setViewBill(true);
  };
  const closeContract = () => {
    setSelectedBill(null);
    setViewBill(false);
  };
  const closeBill = () => {
    setViewBill(false);
    setSelectedBill(null);
  };
  useEffect(() => {
    if (localRequests.length == 0) {
      setSelectedRequest(false);
    }
  }, [localRequests]);
  const handleCloseModal = () => {
    setIsModalOpen(false);
    refreshDatas();
    if (localStorage.getItem("needTutorial") === "true") {
      console.log("reprise du tutoriel");
      resumeJoyride();
    }
  };
  const getLocaux = async () => {
    let result = await fetch("https://api.coverage-eip.com/domicile", {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    result = await result.json();
    console.log(result);
    setLocaux(result);
    if (localId) {
      setSelectedLocal(result.find((local) => local.id === localId));
    }
  };
  const getLocalRequests = async () => {
    let result = await fetch(
      "https://api.coverage-eip.com/domicileRequest/contact",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    result = await result.json();
    console.log(result);
    setLocalRequests(result);
  };
  const refreshDatas = () => {
    getLocaux();
    getLocalRequests();
  };
  useEffect(() => {
    refreshDatas();
  }, []);
  useEffect(() => {
    if (localRequests.length == 0) {
      setSelectedRequest(false);
    }
  }, [localRequests]);
  const CreateLocal = () => {
    refreshDatas();
    setSelectedRequest(false);
    if (localStorage.getItem("needTutorial") === "true") {
      console.log(locaux[0]);
      setSelectedLocal(locaux[0]);
    }
    setSelectedLocal({});
    setEditable(false);
    handleCloseModal();
    handleOpenModal();
  };
  useEffect(() => {
    console.log(openModalForTuto);
    if (openModalForTuto) {
      openModalForTuto.current = handleOpenModal;
    }
  }, [openModalForTuto]);
  const EditLocal = () => {
    refreshDatas();
    setEditable(true);
    handleOpenModal();
  };
  const SelectLocal = (local) => {
    setSelectedRequest(false);
    setSelectedLocal(local);
  };
  const SelectRequest = () => {
    setSelectedLocal({});
    setSelectedRequest(true);
  };
 
  return (
    <>
      {viewBill && <BillsDisplay bill={selectedBill} closeBill={closeBill} listBills={filteredBills} />}
      {viewContract && (
        <ContractDisplay contract={selectedBill} closeDisplay={closeContract} />
      )}
      {!viewBill && !viewContract && (
        <div className="container-page">
          <div id="title-dashboard">
            {" "}
            <img className="" src="white-house.svg" alt="Icon Dashboard" />{" "}
            Locaux
          </div>
          <LocauxDisplayAdd
            Locaux={locaux}
            CreateLocal={CreateLocal}
            SelectLocal={SelectLocal}
            localId={localId}
            localRequests={localRequests}
            SelectRequest={SelectRequest}
            reqView={selectedRequest}
          />
          {!selectedRequest && (
            <>
              <div id="container-infos-local-selected">
                <LocauxElem local={selectedLocal} editLocal={EditLocal} />
                <BillsContrats
                  local={selectedLocal}
                  seeBill2={seeBill}
                  seeContract2={seeContract}
                />
              </div>
            </>
          )}
          <UniversalModal isOpen={isModalOpen} onClose={handleCloseModal}>
            <LocalPopupContent
              state={isModalOpen}
              onClose={handleCloseModal}
              editable={editable}
              initialValues={editable ? selectedLocal : {}}
            />
          </UniversalModal>
        </div>
      )}
    </>
  );
};

export default Home;
