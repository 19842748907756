import React, { useState } from "react";
import "./PictureHover.css"; // Assurez-vous de créer ce fichier CSS

const PictureHover = ({ photo, onPhotoClick }) => {
  const [isHovered, setIsHovered] = useState(false);

  const returnClick = () => {
    console.log("click");
    onPhotoClick();
  }

  return (
    <div
      className="banner-container"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onPhotoClick}
    >
      {photo ? (
        <div
          className="banner-image"
          style={{
            backgroundImage: `url(${photo})`,
          }}
        >
          {isHovered && (
            <div className="banner-overlay">
              <img
                src="appareil-photos.svg"
                alt="Camera Icon"
                className="camera-icon"
              />
              <div>Modifier</div>
            </div>
          )}
        </div>
      ) : (
        <div
          className="banner-image"
          style={{
            backgroundColor: "black",
          }}
        >
          <div className="banner-overlay">
            <img
              src="appareil-photos.svg"
              alt="Camera Icon"
              className="camera-icon"
            />
            <div>Modifier</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PictureHover;
