import React, { useState } from "react";
import "./register.css";

const Register = () => {
  // eslint-disable-next-line
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password_verif, setPassword_verif] = useState("");
  const [first_name, setFirstname] = useState("");
  const [last_name, setLastname] = useState("");
  const [phone, setPhone] = useState("");

  const changePhone = (event) => {
    setPhone(event.target.value);
  };
  const changeLastname = (event) => {
    setLastname(event.target.value);
  };
  const changeFirstname = (event) => {
    setFirstname(event.target.value);
  };
  const changeEmail = (event) => {
    setEmail(event.target.value);
  };
  const changePassword = (event) => {
    setPassword(event.target.value);
  };
  const changePassword_verif = (event) => {
    setPassword_verif(event.target.value);
  };

  const verifCreds = async () => {
    if (password !== password_verif) {
      alert("Les mots de passe ne correspondent pas.");
      return;
    }
    if (
      !password.match(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,12}$/g
      )
    ) {
      alert(
        "Le mot de passe doit contenir une majuscule, une minuscule, un chiffre, un caractère spécial et 8 caractères au minimum."
      );
      return;
    }
    // eslint-disable-next-line
    if (!email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)) {
      alert("Mauvais format d'adresse email");
      return;
    }
    registerRequest();
  };

  const registerRequest = async () => {
    let result = await fetch("https://api.coverage-eip.com/auth/register", {
      method: "POST",
      body: JSON.stringify({
        firstName: first_name,
        email: email,
        lastName: last_name,
        password: password,
        phoneNumber: phone,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log(result);
    if (result.status === 200) {
      result = result.json().then((data) => {
        localStorage.setItem("token", data.token);
        localStorage.setItem("needTutorial", true);
      });
      window.location = "/Dashboard";
    } else {
      result = await result.json();
      localStorage.setItem("token", null);
      alert(result.msg);
      return;
    }
  };

  return (
    <div id="registerPage">
      <div id="registerContainer">
        <div id="form-register-container">
          <div id="title-register-container">Créer un compte</div>
          <div id="subtitle-register-container">
            Saisissez vos informations pour créer votre compte Coverage
          </div>
        <div id="form-register-inputs-container">
            <div id="form-register-inputs-right">
              <input
                type="text"
                placeholder="Nom"
                className="inputs-register"
                value={last_name}
                onChange={changeLastname}
              />
              <input
                type="text"
                placeholder="Prénom"
                className="inputs-register"
                value={first_name}
                onChange={changeFirstname}
              />
              <input
                type="tel"
                placeholder="Numéros de téléphone"
                className="inputs-register"
                value={phone}
                onChange={(e) => {
                  const value = e.target.value;
                  const regex = /^[0-9-\s()]*$/;
                  if (regex.test(value)) {
                    changePhone(e);
                  }
                }}
              />
            </div>
            <div id="form-register-inputs-left">
              <input
                type="text"
                placeholder="Addresse email"
                className="inputs-register"
                value={email}
                onChange={changeEmail}
              />
              <input
                type="password"
                placeholder="Mot de passe"
                className="inputs-register"
                value={password}
                onChange={changePassword}
              />
              <input
                type="password"
                placeholder="Confirmation mot de passe"
                className="inputs-register"
                value={password_verif}
                onChange={changePassword_verif}
              />
            </div>
          </div>
          <div id="container-buttons-register">
            <button
              id="back-button-register"
              onClick={() => (window.location = "/Login")}
            >
              Retour
            </button>
            <button id="connect-button-register" onClick={verifCreds}>
              C'est partis
            </button>
          </div>
          <div className="separator">
            <div className="line"></div>
            <span className="separator-text">OU</span>
            <div className="line"></div>
          </div>
          <div id="container-buttons-register-extern">
            <button id="apple-button-register">
              <img
                src="google-icon.png"
                alt="Google Icon"
                id="button-icon-google"
              />
              Continuer avec Google
            </button>
            <button id="google-button-register">
              <img
                src="apple-icon.png"
                alt="Apple Icon"
                id="button-icon-apple"
              />
              Continuer avec Apple
            </button>
          </div>
        </div>
        <div id="register-img-container">
          <img
            src="mockup-register.jpg"
            alt="mockup-register-pic"
            id="mockup-register"
          />
        </div>
      </div>
    </div>
  );
};

export default Register;
