const CheckAuth = () => {
  let test = null;

  localStorage.getItem("token")
    ? (test = localStorage.getItem("token"))
    : (test = "null");
  if (test !== "null") {
    return true;
  } else {
    return false;
  }
};

export default CheckAuth;
