import React, { useState, useEffect } from "react";
import "./LocalPopupContent.css";
import PictureHover from "../PictureHover/PictureHover";

const LocalPopupContent = ({
  state,
  onClose,
  editable,
  initialValues = {},
}) => {
  const [localValues, setLocalValues] = useState(initialValues);
  const [tampPicture, setTampPicture] = useState(null);

  const disable =
    !localValues.domicileName ||
    !localValues.number ||
    !localValues.street ||
    !localValues.city ||
    !localValues.postalCode ||
    !localValues.country;

  const handleChange = (field) => (event) => {
    setLocalValues((prevValues) => ({
      ...prevValues,
      [field]: event.target.value,
    }));
  };

  useEffect(() => {
    setLocalValues(initialValues);
  }, [initialValues]);

  useEffect(() => {
    if (!state) {
      setLocalValues({});
    }
  }, [state]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const maxSize = 800 * 1024;

    if (file.size > maxSize) {
      alert(
        "Le fichier est trop volumineux. La taille maximale autorisée est de 800 Ko."
      );
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const base64String = e.target.result;
      setTampPicture(base64String);
      setLocalValues((prevValues) => ({
        ...prevValues,
        photo: base64String,
      }));
    };
    reader.readAsDataURL(file);
  };

  const handleClickPicture = () => {
    document.getElementById("fileInputEdit").click();
  };

  const deleteLocal = async () => {
    let result = await fetch(
      `https://api.coverage-eip.com/domicile/${localValues.id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    if (result.status === 200) {
      setLocalValues({});
      onClose();
      window.location.reload();
    } else {
      alert("Code d'arreur : " + result.status);
    }
  };

  const createOrUpdateLocal = async () => {
    let picData = null;
    if (disable) {
      return;
    }
    const isNewPhotoBase64 =
      typeof localValues.photo === "string" &&
      localValues.photo.startsWith("data:image");
    if (localValues.photo) {
      picData = isNewPhotoBase64
        ? localValues.photo.split(",")[1]
        : localValues.photo.url;
    }
    const requestBody = {
      domicileName: localValues.domicileName,
      country: localValues.country,
      city: localValues.city,
      postalCode: localValues.postalCode,
      street: localValues.street,
      number: localValues.number,
      photo: picData,
    };

    const url = editable
      ? `https://api.coverage-eip.com/domicile/${localValues.id}`
      : "https://api.coverage-eip.com/domicile";

    const method = editable ? "PUT" : "POST";

    let result = await fetch(url, {
      method,
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(requestBody),
    });

    if (result.status === 200) {
      setLocalValues({});
      onClose();
    } else {
      alert("Code d'arreur : " + result.status);
    }
  };

  const handleClose = () => {
    setLocalValues({});
    setTampPicture(null);
    if (onClose) {
      onClose();
    }
  };

  return (
    <>
      <img
        className="cross-popup"
        src="cross.svg"
        alt="Icon cross"
        onClick={handleClose}
      />
      <div id="title-popup">
        {editable ? "Modifier un local" : "Créer un local"}
      </div>
      <div id="pic-local-popup">Bannière</div>
      <div style={{ width: "100%", height: "250px", marginBottom: "20px" }}>
        <PictureHover
          photo={tampPicture ? tampPicture : localValues.photo?.url}
          onPhotoClick={() => handleClickPicture()}
        />
      </div>
      <input
        type="file"
        id="fileInputEdit"
        style={{ display: "none" }}
        onChange={handleFileChange}
        accept="image/jpeg, image/png"
      />
      <div>Nom</div>
      <div>
        <input
          type="text"
          id="name-local-popup"
          placeholder="Nom du local"
          value={localValues.domicileName || ""}
          onChange={handleChange("domicileName")}
        />
      </div>
      <div>Adresse</div>
      <div>
        <input
          type="text"
          id="number-local-popup"
          placeholder="Numéros"
          value={localValues.number || ""}
          onChange={(e) => {
            const value = e.target.value;
            const regex = /^[0-9]*$/;
            if (regex.test(value)) {
              handleChange("number")(e);
            }
          }}
        />
        <input
          type="text"
          id="street-local-popup"
          placeholder="Rue"
          value={localValues.street || ""}
          onChange={handleChange("street")}
        />
        <input
          type="text"
          id="city-local-popup"
          placeholder="Ville"
          value={localValues.city || ""}
          onChange={handleChange("city")}
        />
        <input
          type="text"
          id="postal-local-popup"
          placeholder="Code postal"
          value={localValues.postalCode || ""}
          onChange={(e) => {
            const value = e.target.value;
            const regex = /^[0-9]*$/;
            if (regex.test(value)) {
              handleChange("postalCode")(e);
            }
          }}
        />
        <input
          type="text"
          id="country-local-popup"
          placeholder="Pays"
          value={localValues.country || ""}
          onChange={handleChange("country")}
        />
      </div>

      {editable && (
        <div style={{ display: "flex" }}>
          <div
            id="button-create-local-popup"
            onClick={deleteLocal}
            style={{
              marginRight: "10px",
              width: "35%",
              backgroundColor: "#EA7F7F",
            }}
          >
            Supprimer le local
          </div>
          <div
            id="button-create-local-popup"
            className={disable ? "disable-button" : ""}
            onClick={createOrUpdateLocal}
            disabled={disable}
            style={{ width: "60%" }}
          >
            Appliquer
          </div>
        </div>
      )}
      {!editable && (
        <div
          id="button-create-local-popup"
          className={disable ? "disable-button" : ""}
          onClick={createOrUpdateLocal}
          disabled={disable}
        >
          Créer
        </div>
      )}
    </>
  );
};

export default LocalPopupContent;
