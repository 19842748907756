import React from "react";
import "./PricingLanding.css";

const PricingLanding = () => {
  return (
    <div id="pricing-section-container-background">
      <div id="pricing-section-container">
        <div id="title-pricing">
          Nos différentes formules, pour s'adapter à tous les besoins et tous
          les budgets !
        </div>
        <div id="card-pay-container">
          <div className="card-price-basic">
            <div className="card-container card-basic">
              <div className="name-formule-pay">Basique</div>
              <div className="subtext-pay">
                Pour un usage limité et occasionnel
              </div>
              <div className="functions-formule-pay">
                <div className="text-icon-pay">
                  <img src="cloud-cross.svg" />
                  Stockage local uniquement
                </div>
                <div className="text-icon-pay">
                  <img src="brain-icon-red.svg" /> 1 Simulation par jour
                </div>
              </div>
              <div className="card-pay-price">Gratuit</div>
            </div>
            <div className="button-container-souscrire">
              <div className="button-select-add">Souscrire</div>
            </div>
          </div>
          <div className="card-price-prenium">
            <div className="card-container card-prenium">
              <div className="name-formule-pay">Prenium</div>
              <div className="subtext-pay">
                Soyez serein dans la gestion de vos contrats et factures{" "}
              </div>
              <div className="functions-formule-pay">
                <div className="text-icon-pay">
                  <img src="cloud.svg" />
                  Stockage local & 100 fichiers Cloud
                </div>
                <div className="text-icon-pay">
                  <img src="brain-icon.svg" />
                  Simulations en illimité
                </div>
              </div>
              <div className="card-pay-price">5,99 € / Mois</div>
            </div>
            <div className="button-container-souscrire">
              <div className="button-select-add">Souscrire</div>
            </div>
          </div>
          <div className="card-price-pro">
            <div className="card-container card-pro">
              <div className="name-formule-pay-pro">Pro</div>
              <div className="subtext-pay">
                Pour un usage illimité et être serein en toute circonstances
              </div>
              <div className="functions-formule-pay">
                <div className="text-icon-pay">
                  <img src="purple-cloud.svg" />
                  Stockage local & Cloud illimité
                </div>
                <div className="text-icon-pay">
                  <img src="brain-icon-purple.svg" />
                  Simulations en illimité
                </div>
              </div>
              <div className="card-pay-price">9,99 € / Mois</div>
            </div>
            <div className="button-container-souscrire">
              <div className="button-select-add">Souscrire</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingLanding;
