import React, { useEffect, useState } from "react";
import "./CustomSelect.css";

function CustomSelect({
  options,
  placeholder,
  selectedOption,
  alreadySelected,
}) {
  const [selected, setSelected] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (option) => {
    setSelected(option);
    selectedOption(option);
    setIsOpen(false);
  };

  useEffect(() => {
    if (alreadySelected) {
      setSelected(alreadySelected);
    }
  }, [alreadySelected]);

  return (
    <div className="custom-select">
      <div className="custom-select-header" onClick={() => setIsOpen(!isOpen)}>
        {selected ? selected.domicileName : placeholder}
        <img src="dropdown-arrow.svg" alt="" />
      </div>
      {isOpen && (
        <div className="custom-select-options">
          {options && options.length > 0 ? (
            options.map((option) => (
              <div
                key={option.id}
                className="custom-select-option"
                onClick={() => handleSelect(option)}
              >
                {option.photo ? (
                  <img src={option.photo.url} alt={option.domicileName} />
                ) : (
                  <img src="house.svg" alt="default house" />
                )}
                {option.domicileName}
              </div>
            ))
          ) : (
            <div id="no-option" style={{ padding: "10px 20px" }}>
              Aucun local
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default CustomSelect;
