import React from "react";
import "./TitlePage.css";

const TitlePage = ({ TitlePage }) => {
  return (
    <div>
      <h3 className="title_page">{TitlePage}</h3>
    </div>
  );
};

export default TitlePage;
