import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  MenuItem,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Menu,
  Chip,
  InputBase,
} from '@mui/material';
import { styled } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CancelIcon from '@mui/icons-material/Cancel';

const SearchBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  padding: '8px',
  backgroundColor: '#fff',
  borderRadius: '20px',
  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
  width: '100%',
  fontFamily: 'Arial, sans-serif',
});

const SearchInput = styled(InputBase)({
  marginLeft: '8px',
  flexGrow: 1,
  fontSize: '16px',
  color: '#5c6bc0',
  '& .MuiInputBase-input': {
    padding: '8px 0',
  },
});

const DropdownButton = styled(Button)({
  borderRadius: '20px',
  textTransform: 'none',
  color: '#5c6bc0',
  borderColor: '#5c6bc0',
  fontSize: '16px',
});

const StyledChip = styled(Chip)({
  marginLeft: '8px',
  backgroundColor: 'white',
  color: '#5c6bc0',
  fontSize: '12px',
  span: {
    paddingRight: '5px',
  },
  '& .MuiChip-deleteIcon': {
    color: '#5E63B6',
    width: '16px',
    height: '16px',
  },
});

const StyledMenu = styled(Menu)({
  '& .MuiPaper-root': {
    borderRadius: '10px',
    marginTop: '10px',
    backgroundColor: '#fff',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
  },
});

const StyledMenuItem = styled(MenuItem)({
  '&.MuiMenuItem-root': {
    color: '#5c6bc0',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
  },
});

const StyledFormControlLabel = styled(FormControlLabel)({
  '& .MuiCheckbox-root': {
    color: '#5c6bc0',
    '&.Mui-checked': {
      color: '#5c6bc0',
    },
  },
  '& .MuiTypography-root': {
    fontSize: '16px',
  },
});

const SearchBar = ({ bills, options, onFilter }) => {
  const [searchText, setSearchText] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleOptionChange = (event) => {
    const value = event.target.value;
    setSelectedOptions(
      selectedOptions.includes(value)
        ? selectedOptions.filter((option) => option !== value)
        : [...selectedOptions, value]
    );
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = (option) => {
    setSelectedOptions(selectedOptions.filter((item) => item !== option));
  };

  const filterBills = () => {
    return bills.filter((bill) => {
      const matchesSearchText =
        bill.storeName.toLowerCase().includes(searchText.toLowerCase()) ||
        bill.tags.some((tag) =>
          tag.toLowerCase().includes(searchText.toLowerCase())
        );
      const matchesSelectedOptions =
        selectedOptions.length === 0 ||
        selectedOptions.some((option) => bill.tags.includes(option));
      return matchesSearchText && matchesSelectedOptions;
    });
  };

  useEffect(() => {
    onFilter(filterBills());
  }, [searchText, selectedOptions, bills]);

  return (
    <SearchBox>
      <SearchIcon sx={{ color: '#5E63B6' }} />
      <SearchInput
        value={searchText}
        onChange={handleSearchChange}
        placeholder="Rechercher"
      />
      {selectedOptions.map((option) => (
        <StyledChip
          key={option}
          label={option}
          onDelete={() => handleDelete(option)}
          deleteIcon={<CancelIcon />}
        />
      ))}
      <FormControl>
        <DropdownButton
          endIcon={<ArrowDropDownIcon />}
          onClick={handleMenuOpen}
        >
          Types
        </DropdownButton>
        <StyledMenu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {options.map((option) => (
            <StyledMenuItem key={option} value={option}>
              <StyledFormControlLabel
                control={
                  <Checkbox
                    checked={selectedOptions.includes(option)}
                    onChange={handleOptionChange}
                    value={option}
                  />
                }
                label={option}
              />
            </StyledMenuItem>
          ))}
        </StyledMenu>
      </FormControl>
    </SearchBox>
  );
};

export default SearchBar;
