import React, { useState } from "react";
import "./BlurSquares.css";

const BlurSquares = ({ generateCode, isBlur, code }) => {
  const [blur, setBlur] = useState(isBlur);

  const handleButtonClick = () => {
    setBlur(false);
    generateCode();
  };

  return (
    <div className="blur-squares-container">
      <div className={`squares ${blur ? "blurred" : ""}`}>
        {Array.from({ length: 8 }).map((_, index) => (
          <div style={{ border: "1px solid black", borderRadius: "8px" }}>
            {code && (
              <div key={index} className="square">
                {code[index]}
              </div>
            )}
            {!code && (
              <div key={index} className="square">
                {index + 1}
              </div>
            )}
          </div>
        ))}
      </div>
      {blur && (
        <button className="toggle-blur-button" onClick={handleButtonClick}>
          Générer un code
        </button>
      )}
      {!blur && (
        <div
          className="toggle-blur-button"
          onClick={() => {
            navigator.clipboard.writeText(code);
          }}
        >
          Copier le code
        </div>
      )}
    </div>
  );
};

export default BlurSquares;
