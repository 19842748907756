import React from "react";
import "./CardMemberTeam.css";

const CardMemberTeam = ({ name, picture, job }) => {
  return (
    <div className="card-member-team">
      <div className="name-member-team">{name}</div>
      <div className="job-member-team">{job}</div>
      <div className="memoji-container">{picture}</div>
    </div>
  );
};

export default CardMemberTeam;
