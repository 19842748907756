import React from "react";
import "./LocauxElem.css";
import CircularProgressBar from "../CircularProgressBar/CircularProgressBar";
import PictureHover from "../PictureHover/PictureHover";
import UniversalModal from "../UniversalModal/UniversalModal";
import BlurSquares from "../BlurSquares/BlurSquares"; // Assurez-vous que le chemin est correct
import { useState, useEffect } from "react";

const LocauxElem = ({ local, editLocal }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [joinCode, setJoinCode] = useState("");
  const [expirationTime, setExpirationTime] = useState(null);

  // Vérification et récupération du code au changement de domicile
  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem(`joinCode_${local.id}`));
    console.log(storedData);
    if (storedData) {
      const { tampCode, expiration } = storedData;
      if (new Date().getTime() < expiration) {
        setJoinCode(tampCode);
        setExpirationTime(expiration);
      } else {
        // Supprime le code expiré du localStorage
        localStorage.removeItem(`joinCode_${local.id}`);
        setJoinCode("");
        setExpirationTime(null);
      }
    } else {
      setJoinCode("");
      setExpirationTime(null);
    }
  }, [local.id]);

  const generateCode = async () => {
    const result = await fetch(
      "https://billtype.api.coverage-eip.com/domicileRequest/createCode/" + local.id, {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify({
          "force": true
        }),
      }
    );
    if (result.status !== 200) {
      alert("Erreur lors de la génération du code");
      return;
    }
    let data = await result.json();
    console.log(data);
    let tampCode = data.code;
    if (!joinCode) {
      // let code = "";
      // for (let i = 0; i < 10; i++) {
      //   code += Math.floor(Math.random() * 10);
      // }
      const expiration = new Date().getTime() + 60 * 60 * 1000; // 1 heure
      setJoinCode(data.code);
      setExpirationTime(expiration);

      // Stocker le code et l'heure d'expiration dans localStorage
      localStorage.setItem(
        `joinCode_${local.id}`,
        JSON.stringify({ tampCode, expiration })
      );
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const HandleEditLocal = () => {
    editLocal();
  };

  const deleteLocal = async () => {
    let result = await fetch(
      "https://api.coverage-eip.com/domicile/" + local.id,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify({
          domicileId: local.id,
        }),
      }
    );
    result = await result.json();
    window.location.reload();
  };

  return (
    <>
      {local?.address && (
        <div id="container-infos-local-selected-details">
          <div id="container-pic-details-infos-local">
            <PictureHover
              photo={local.photo ? local.photo.url : null}
              onPhotoClick={() => HandleEditLocal()}
            />
          </div>
          <div id="container-local-info-details">
            <div id="address-local-details">
              <img src="purple-pin.svg" alt="" />
              {local.address}
            </div>
            <div id="nb-colab-local-details">
              <img src="group-people.svg" alt="" />
              {local.tenant.length} Collaborateurs
            </div>
            <div id="container-double-infos-local">
              <div id="container-double-infos-local">
                <img src="purple-files.svg" alt="" />
                <div id="nb-contrat-local-details">
                  {local.contracts.length} Contrats
                </div>
                <div id="separator-nb-infos">/</div>
                <div id="nb-facture-local-details">
                  {local.bills.length} Factures
                </div>
              </div>
            </div>
            <div
              className="button-action-local-details"
              onClick={() => setIsModalOpen(true)}
            >
              Inviter un collaborateur
              <img src="white-people.svg" alt="" />
            </div>
            <div className="button-action-local-details" onClick={editLocal}>
              Modifier le local
              <img src="white-pen.svg" alt="" />
            </div>
          </div>
          <div id="container-progressbar-local">
            <CircularProgressBar percentage={85} />
          </div>
          <UniversalModal isOpen={isModalOpen} onClose={handleCloseModal}>
            <div style={{ width: "30vw", minWidth: "600px", padding: "20px" }}>
              <img
                className="cross-popup"
                src="cross.svg"
                alt="Icon cross"
                onClick={handleCloseModal}
              />
              <div>
                <div
                  style={{
                    fontSize: "24px",
                    color: "#5E63B6",
                    fontWeight: "700",
                    marginBottom: "10px",
                  }}
                >
                  Inviter un collaborateur
                </div>
                <div style={{marginBottom: '20px'}}>
                  Générer un code unique pour inviter un collaborateur à
                  rejoindre votre local. Ce code est valable 1h.
                </div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      marginBottom: "20px",
                    }}
                    >
                      <BlurSquares isBlur={joinCode? false : true} generateCode={() => generateCode()} code={joinCode}/>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                ></div>
              </div>
            </div>
          </UniversalModal>
        </div>
      )}
    </>
  );
};

export default LocauxElem;
