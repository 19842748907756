import React, { useState } from "react";
import "./Feedback.css";

const Feedback = () => {
  const [feedback, setFeedback] = useState({
    navigation: "",
    design: "",
    usability: "",
    features: "",
    improvements: "",
    overall: "",
    recommendation: "",
    easeOfFinding: null,
    satisfaction: null,
    comments: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFeedback((prevFeedback) => ({
      ...prevFeedback,
      [name]: value,
    }));
  };

  const handleRatingChange = (name, value) => {
    setFeedback((prevFeedback) => ({
      ...prevFeedback,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Feedback submitted:", feedback);
    // Ajoutez votre logique pour envoyer les données du feedback à votre serveur
  };

  return (
    <div className="feedback-container">
      <h1>Feedback de l'application</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Comment évaluez-vous la navigation sur l'application ?
          <select
            name="navigation"
            value={feedback.navigation}
            onChange={handleChange}
          >
            <option value="">Sélectionner</option>
            <option value="excellent">Excellent</option>
            <option value="good">Bon</option>
            <option value="average">Moyen</option>
            <option value="poor">Mauvais</option>
          </select>
        </label>
        <label>
          Comment trouvez-vous le design de l'application ?
          <select
            name="design"
            value={feedback.design}
            onChange={handleChange}
          >
            <option value="">Sélectionner</option>
            <option value="excellent">Excellent</option>
            <option value="good">Bon</option>
            <option value="average">Moyen</option>
            <option value="poor">Mauvais</option>
          </select>
        </label>
        <label>
          La facilité d'utilisation de l'application ?
          <select
            name="usability"
            value={feedback.usability}
            onChange={handleChange}
          >
            <option value="">Sélectionner</option>
            <option value="excellent">Excellent</option>
            <option value="good">Bon</option>
            <option value="average">Moyen</option>
            <option value="poor">Mauvais</option>
          </select>
        </label>
        <label>
          Les fonctionnalités de l'application ?
          <select
            name="features"
            value={feedback.features}
            onChange={handleChange}
          >
            <option value="">Sélectionner</option>
            <option value="excellent">Excellent</option>
            <option value="good">Bon</option>
            <option value="average">Moyen</option>
            <option value="poor">Mauvais</option>
          </select>
        </label>
        <label>
          Recommanderiez-vous cette application à un ami ?
          <select
            name="recommendation"
            value={feedback.recommendation}
            onChange={handleChange}
          >
            <option value="">Sélectionner</option>
            <option value="definitely">Certainement</option>
            <option value="probably">Probablement</option>
            <option value="notSure">Pas sûr</option>
            <option value="probablyNot">Probablement pas</option>
            <option value="definitelyNot">Certainement pas</option>
          </select>
        </label>
        <label>
          Notez la facilité de trouver les informations (1-5)
          <div className="rating-container">
            {[1, 2, 3, 4, 5].map((value) => (
              <div
                key={value}
                className={`rating-box ${
                  feedback.easeOfFinding === value ? "selected" : ""
                }`}
                onClick={() => handleRatingChange("easeOfFinding", value)}
              >
                {value}
              </div>
            ))}
          </div>
        </label>
        <label>
          Votre niveau de satisfaction global (1-10)
          <div className="rating-container">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => (
              <div
                key={value}
                className={`rating-box ${
                  feedback.satisfaction === value ? "selected" : ""
                }`}
                onClick={() => handleRatingChange("satisfaction", value)}
              >
                {value}
              </div>
            ))}
          </div>
        </label>
        <label>
          Des suggestions d'améliorations ?
          <textarea
            name="improvements"
            value={feedback.improvements}
            onChange={handleChange}
          />
        </label>
        <label>
          Note globale de l'application (développement) ?
          <textarea
            name="overall"
            value={feedback.overall}
            onChange={handleChange}
          />
        </label>
        <label>
          Autres commentaires
          <textarea
            name="comments"
            value={feedback.comments}
            onChange={handleChange}
          />
        </label>
        <button type="submit" style={{marginTop: '40px'}}>Soumettre</button>
      </form>
    </div>
  );
};

export default Feedback;
