import React, { useEffect, useState } from "react";
import "./LocauxDisplayAdd.css";
import { useNavigate } from "react-router-dom";
import CustomSelect from "./CustomSelect";
import UniversalModal from "../UniversalModal/UniversalModal";

const LocauxDisplayAdd = ({
  SelectLocal,
  CreateLocal,
  Locaux,
  localId,
  localRequests,
  SelectRequest,
  reqView,
}) => {
  const [selectedLocal, setSelectedLocal] = useState(null);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [joinCode, setJoinCode] = useState("");

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setJoinCode("");
  };

  const ChooseLocal = (local) => {
    setSelectedLocal(local);
    SelectLocal(local);
    navigate({ ...navigate.location, state: null }, { replace: true });
  };

  const HandleCreateLocal = () => {
    setSelectedLocal({});
    SelectLocal({});
    CreateLocal();
  };

  useEffect(() => {
    if (localId) {
      const foundLocal = Locaux.find((local) => local.id === localId);
      if (foundLocal) {
        setSelectedLocal(foundLocal);
        SelectLocal(foundLocal);
      }
    } else if (
      localStorage.getItem("needTutorial") === "true" &&
      Locaux.length > 0
    ) {
      setSelectedLocal(Locaux[0]);
      ChooseLocal(Locaux[0]);
    }
  }, [Locaux, localId]);

  const handleJoinLocal = async () => {
    if (joinCode) {
      // :TODO: Call the API to join the local
      const result = await fetch(
        "https://billtype.api.coverage-eip.com/domicileRequest/acceptCode/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify({
            code: joinCode,
          }),
        }
      );
      console.log(result);
      if (result.status === 200) {
        setIsModalOpen(false);
        setJoinCode("");
      } else {
        alert("Code incorrect");
      }

    }
  };

  return (
    <div id="parent-container-domiciles-list">
      <div id="container-domiciles-list">
        <div id="container-dropdown-list-local">
          <CustomSelect
            options={Locaux}
            placeholder="Selectionnez un local"
            alreadySelected={selectedLocal}
            selectedOption={(option) => {
              setSelectedLocal(option);
              SelectLocal(option);
              navigate({ ...navigate.location, state: null }, { replace: true });
            }}
          />
        </div>
        <div id="add-local-btn" onClick={HandleCreateLocal}>
          Ajouter un local
          <img src="add-local-icon.svg" alt="" />
        </div>
        <div id="join-local-btn" onClick={() => setIsModalOpen(true)}>
          Rejoindre un local
          <img src="join-local.svg" alt="" />
        </div>
        <UniversalModal isOpen={isModalOpen} onClose={handleCloseModal}>
          <div style={{ width: "30vw", minWidth: "600px", padding: "20px" }}>
            <img
              className="cross-popup"
              src="cross.svg"
              alt="Icon cross"
              onClick={handleCloseModal}
            />
            <div>
              <div
                style={{
                  fontSize: "24px",
                  color: "#5E63B6",
                  fontWeight: "700",
                  marginBottom: "10px",
                }}
              >
                Rejoindre un local
              </div>
              <div>
                <input
                  type="text"
                  value={joinCode}
                  onChange={(e) => setJoinCode(e.target.value)}
                  style={{
                    backgroundColor: "#E9E5FA",
                    width: "90%",
                    margin: "2em 0px",
                    borderRadius: "10px",
                    padding: "1.2em",
                    border: "none",
                  }}
                  placeholder="Code du local"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <div
                  className={`join-button ${!joinCode ? "disabled" : ""}`}
                  onClick={joinCode ? handleJoinLocal : null}
                >
                  Rejoindre
                </div>
              </div>
            </div>
          </div>
        </UniversalModal>
      </div>
    </div>
  );
};

export default LocauxDisplayAdd;
