import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <div id="footer">
      <div id="container-txt-footer">
        <div id="coverage-title-footer">Coverage</div>
        <div className="whitxt coverage-txt-footer">
          Projet réalisé par Thibaud Fontaine, Hugo Deremetz, Charly Le Corre,
          Dylan Laroye, Hugo Delille, Clément Monteiro, Mathis Léchopier,
          Cyprien Singez.
        </div>
        <div>
          <img src="social-networks.svg" />
        </div>
      </div>
      <div id="container-text-left-footer">
        <div className="whitxt">Liens</div>
        <div className="links-container-footer">
          <a href="/">Téléchargement</a>
          <div className="link-container-footer">
            <a href="/login">Se connecter</a>
            <a href="/register">S'inscrire</a>
          </div>
          <div className="link-container-footer">
            <a href="/">Nous contacter</a>
            <a href="/">Newsletter</a>
          </div>
        </div>
        <div className="whitxt">
          <img src="epitech.svg" /> Epitech Innovative Project
        </div>
        <div className="whitxt">
          <img src="white-coverage.svg" />
          Coverage tous droits réservés © 2023
        </div>
      </div>
    </div>
  );
};

export default Footer;
