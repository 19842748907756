import React from "react";

const getBills = async () => {
  // let result = await fetch("https://api.prod.coverage-eip.com/readBill", {
  //   method: "get",
  //   credentials: "include",
  //   mode: 'no-cors',
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: "Bearer " + localStorage.getItem("token")
  //   },
  // });
  // result = await result.json();
};
const sortTab = (tab) => {
  let tamp = tab
    .filter((each) => each.isFavorite === true)
    .sort((a, b) => a.Filename.localeCompare(b.Filename));
  let tamp2 = tab
    .filter((each) => each.isFavorite === false)
    .sort((a, b) => a.Filename.localeCompare(b.Filename));
  return tamp.concat(tamp2);
};
const generateTab = () => {
  getBills();
  let tamp = [
    {
      id: 1,
      Filename: "Nom du fichier",
      photo: "pdf_adobe.jpg",
      isFavorite: false,
    },
    {
      id: 2,
      Filename: "Test",
      photo: "pdf_adobe.jpg",
      isFavorite: false,
    },
    {
      id: 3,
      Filename: "Toto",
      photo: "facture_example.png",
      isFavorite: true,
    },
    {
      id: 4,
      Filename: "No name",
      photo: "pdf_adobe.jpg",
      isFavorite: false,
    },
    {
      id: 5,
      Filename: "Nom de famille",
      photo: "pdf_adobe.jpg",
      isFavorite: false,
    },
    {
      id: 6,
      Filename: "Facture voiture",
      photo: "pdf_adobe.jpg",
      isFavorite: false,
    },
    {
      id: 7,
      Filename: "Assurance maison",
      photo: "facture_example.png",
      isFavorite: true,
    },
    {
      id: 8,
      Filename: "Facture EDF",
      photo: "pdf_adobe.jpg",
      isFavorite: false,
    },
  ];
  return sortTab(tamp);
};

export { generateTab };
export { sortTab };
export { getBills };
