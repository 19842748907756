import React, { useState, useEffect } from "react";
import { Button, Modal } from "@mui/material";

const UniversalModal = ({ isOpen, onClose, children }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };

    window.addEventListener("resize", handleResize);

    // Nettoyage de l'événement lorsque le composant est démonté
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getStyle = () => {
    return {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "white",
      boxShadow: 24,
      outline: "none",
      borderRadius: 8,
      padding: 20,
      width: isMobile ? "80%" : "auto",
      maxHeight: isMobile ? "80%" : "auto",
      overflowY: "auto",
      overflowX: "hidden",
    };
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <div style={getStyle()}>
        {children}
      </div>
    </Modal>
  );
};

export default UniversalModal;
