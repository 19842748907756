import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../header/header.css";
import CheckAuth from "../../Auth";
import RateReviewIcon from '@mui/icons-material/RateReview';

const Header = () => {
  const [scroll, setScroll] = useState(false);
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 600px)").matches
  );
  const [activePage, setActivePage] = useState("Home");
  const [isProfileMenuVisible, setIsProfileMenuVisible] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [infosProfil, setInfosProfil] = useState({});
  const timerRef = useRef(null);

  const generateBackgroundColor = (fname, lname) => {
    const initials = `${fname?.charAt(0)}${lname?.charAt(0)}`;
    const colors = [
      "#ff7f0e",
      "#2ca02c",
      "#1f77b4",
      "#d62728",
      "#9467bd",
      "#8c564b",
      "#e377c2",
      "#7f7f7f",
      "#bcbd22",
      "#17becf",
    ];
    const hashCode = initials
      .split("")
      .reduce((acc, char) => char.charCodeAt(0) + acc, 0);
    const colorIndex = hashCode % colors.length;
    return colors[colorIndex];
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
    const handleResize = () => {
      setIsMobile(window.matchMedia("(max-width: 600px)").matches);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getInfosProfil = async () => {
    let result = await fetch("https://api.coverage-eip.com/account", {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    result = await result.json();
    setInfosProfil(result);
    console.log(result);
  };

  useEffect(() => {
    getInfosProfil();
  }, []);

  useEffect(() => {
    const path = location.pathname.split("/")[1];
    setActivePage(path);
  }, [location.pathname]);

  const disconnect = async () => {
    localStorage.clear();
    window.location = "/";
  };

  const handleButtonClick = (pageName) => {
    navigate(`/${pageName}`);
  };

  const handleMouseEnter = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    setIsProfileMenuVisible(true);
  };

  const handleMouseLeave = () => {
    timerRef.current = setTimeout(() => {
      setIsProfileMenuVisible(false);
    }, 300);
  };

  const handleProfileMenuMouseEnter = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  };

  const handleProfileMenuMouseLeave = () => {
    setIsProfileMenuVisible(false);
  };

  const getStyleProfilePic = () => {
    if (isMobile) {
      return {
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      };
    } else {
      return {
        width: "50px",
        height: "50px",
        borderRadius: "50%",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      };
    }
  };

  return (
    <div className={scroll ? "sticky-menu background_menu" : "sticky-menu"}>
      <div id="header">
        <div id="menu">
          {isMobile ? (
            <a id="coverageHome-logo-header" href="/">
              <img src="logo_Coverage.png" alt="" />
            </a>
          ) : (
            <a id="coverageHome" href="/">
              Coverage
            </a>
          )}
          {CheckAuth() ? (
            <>
              <div id="header-right-buttons">
                <div
                  className={`tab-header ${
                    activePage === "Feedback"
                      ? "tab-header-active"
                      : "tab-header-sinactive"
                  }`}
                  onClick={() => handleButtonClick("Feedback")}
                >
                  <RateReviewIcon />
                  Feedback
                </div>
                <div
                  className={`tab-header ${
                    activePage === "Contract"
                      ? "tab-header-active"
                      : "tab-header-sinactive"
                  }`}
                  onClick={() => handleButtonClick("Contract")}
                >
                  <img className="" src="white-contrat.svg" alt="Icon House" />
                  Mes assurances
                </div>
                <div
                  className={`tab-header ${
                    activePage === "Home"
                      ? "tab-header-active"
                      : "tab-header-sinactive"
                  }`}
                  onClick={() => handleButtonClick("Home")}
                >
                  <img className="" src="white-house.svg" alt="Icon House" />
                  Locaux
                </div>
                <div
                  className={`tab-header ${
                    activePage === "Dashboard"
                      ? "tab-header-active"
                      : "tab-header-sinactive"
                  }`}
                  onClick={() => handleButtonClick("Dashboard")}
                >
                  <img className="" src="dashboard.svg" alt="Icon Dashboard" />
                  Dashboard
                </div>
                <div
                  className="profil-pic-container"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <div className="profil-pic-header">
                    {infosProfil.profilePicture && (
                      <img
                        src={infosProfil.profilePicture}
                        alt="side-menu"
                        className="profile-pic"
                      />
                    )}
                    {!infosProfil.profilePicture && (
                      <div
                        style={{ ...getStyleProfilePic(), backgroundColor: generateBackgroundColor(infosProfil.firstName, infosProfil.lastName) }}
                      >
                        {infosProfil.firstName?.charAt(0).toUpperCase()}
                        {infosProfil.lastName?.charAt(0).toUpperCase()}
                      </div>
                    )}
                  </div>
                  {isProfileMenuVisible && (
                    <div
                      id="profile-menu"
                      onMouseEnter={handleProfileMenuMouseEnter}
                      onMouseLeave={handleProfileMenuMouseLeave}
                      onClick={() => navigate("/profil")}
                    >
                      <div id="container-profil-buttons">
                        <button
                          onClick={() => navigate("/profil")}
                          style={{
                            backgroundColor: "#5E63B6",
                            color: "white",
                            marginBottom: "10px",
                          }}
                        >
                          Gérer mon compte
                          <img src="people-white.svg" alt="" />
                        </button>
                        <button
                          onClick={disconnect}
                          style={{ color: "#5E63B6" }}
                        >
                          Se déconnecter
                          <img src="purple-exit.svg" alt="" />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : (
            <div
              className="tab-header"
              onClick={() => (window.location = "/Login")}
            >
              Connexion
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
