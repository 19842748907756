import React from "react";
import "./CardLocal.css";
import { useNavigate } from "react-router-dom";

const CardLocal = ({ localName, localPic, localId }) => {
  const backgroundStyle = localPic
    ? {
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9)), url(${localPic})`,
      }
    : { backgroundColor: "black" };
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate("/Home", { state: { localId: localId } });
  };
  return (
    <>
      <div
        onClick={handleRedirect}
        className="card-local-dashboard-container"
        style={{
          ...backgroundStyle,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="card-local-dashboard-title">{localName}</div>
      </div>
    </>
  );
};

export default CardLocal;
