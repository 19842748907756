import React, { useState } from "react";
import "./login.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [forgetPassword, setForgetPassword] = useState(false);
  var modeleAppareil = navigator.userAgent;

  const changeEmail = (event) => {
    setEmail(event.target.value);
  };
  const changeStatePassword = () => {
    setForgetPassword(!forgetPassword);
  };
  const changePassword = (event) => {
    setPassword(event.target.value);
  };
  const verifCreds = async () => {
    loginRequest();
  };

  const sendForgetPassword = async () => {
    let result = await fetch(
      "https://api.coverage-eip.com/auth/forgot-password",
      {
        method: "post",
        body: JSON.stringify({
          method: "email",
          data: email,
          model: modeleAppareil,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (result.status === 200) {
      result = result.json().then((data) => {
        alert(
          "Un mail de récupération à été envoyé à l'adresse mail " +
            email +
            " renseignée"
        );
      });
    } else {
      alert(result.statusText);
    }
    setEmail("");
    changeStatePassword();
  };

  const getFormLogin = () => {
    if (forgetPassword) {
      return (
        <div id="form-login-container">
          <div id="title-login-container">Identifiants oubliés ?</div>
          <div id="subtitle-login-container">
            Saisissez l'identifiant de votre compte
          </div>
          <input
            type="text"
            placeholder="Addresse mail"
            id="input-login-email"
            value={email}
            onChange={changeEmail}
          />
          <button id="connect-button-login" onClick={sendForgetPassword}>
            Envoyer la demande de récupération
          </button>
          <div className="separator">
            <div className="line"></div>
            <span className="separator-text">OU</span>
            <div className="line"></div>
          </div>
          <a href="/Register" id="register-redirect-login">
            Créer un compte
          </a>
        </div>
      );
    } else {
      return (
        <div id="form-login-container">
          <div id="title-login-container">Heureux de vous revoir</div>
          <div id="subtitle-login-container">
            Saisissez vos informations de connexion pour accéder à votre compte
            Coverage
          </div>
          <input
            type="text"
            placeholder="Addresse mail"
            id="input-login-email"
            value={email}
            onChange={changeEmail}
          />
          <input
            type="password"
            placeholder="Mot de passe"
            id="input-login-password"
            value={password}
            onChange={changePassword}
          />
          <span id="forget-id-login" onClick={getForgetPassword}>
            Identifiants oubliés
          </span>
          <button id="connect-button-login" onClick={verifCreds}>
            Connexion
          </button>
          <div className="separator">
            <div className="line"></div>
            <span className="separator-text">OU</span>
            <div className="line"></div>
          </div>
          <a href="/Register" id="register-redirect-login">
            Créer un compte
          </a>
        </div>
      );
    }
  };

  const getForgetPassword = () => {
    setForgetPassword(true);
    return;
  };

  const loginRequest = async () => {
    let result = await fetch("https://api.coverage-eip.com/auth/login", {
      method: "post",
      body: JSON.stringify({
        email: email,
        password: password,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log(result);
    if (result.status === 200) {
      let token = null;
      result = result.json().then((data) => {
        token = data.token;
        localStorage.setItem("token", token);
        console.log(localStorage.getItem("token"));
        // window.location.href = "/";
      });
      //timeout 0.5s
      await new Promise((r) => setTimeout(r, 200));
      let user = await fetch("https://api.coverage-eip.com/account", {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      user = await user.json();
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("needTutorial", false);
      window.location = "/Dashboard";
    } else {
      alert(result.statusText);
    }
  };
  return (
    <div id="loginPage">
      <div id="loginContainer">
        {getFormLogin()}
        <div id="login-img-container">
          <img
            src="mockup-login.jpg"
            alt="mockup-login-pic"
            className="mockup-login"
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
