import React, { useEffect, useState } from "react";

const ConnectInsurancesPopup = ({ onClose }) => {
  const [selectedInsurance, setSelectedInsurance] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const directAssurance = "66c5ee86944315cd05e9041a";
  const eurofil = "66d630dfcd1018d0bf655d29";

  const handleInsuranceSelect = (insurance) => {
    setSelectedInsurance(insurance);
    setResponseMessage("");
  };

  useEffect(() => {
    const getInsurance = async () => {
      let result = await fetch(
        "https://mono.coverage-eip.com/policies", {
          method: "GET",
          headers: {
            "Authorization": "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/json",
          },
        }
      );
      result = await result.json();
      console.log(result);
    };
    getInsurance();
  }, []);

  const handleLogin = async () => {
    let assuranceId = selectedInsurance === "Direct Assurance" ? directAssurance : eurofil;

    let result = await fetch(
      "https://mono.coverage-eip.com/insurances/" + assuranceId + "/auth", {
        method: "POST",
        body: JSON.stringify({
          id: email,
          password: password,
        }),
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    console.log(result);
    const isSuccess = email === "thibaud269@gmail.com" && password === "1234";

    if (isSuccess) {
      setResponseMessage("Connexion réussie !");
    } else {
      setResponseMessage(
        "Échec de la connexion. Veuillez vérifier vos informations."
      );
    }
  };

  return (
    <div style={{ width: "30vw", minWidth: "600px", padding: "20px" }}>
      <img
        className="cross-popup"
        src="cross.svg"
        alt="Icon cross"
        onClick={onClose}
      />
      <div>
        <div
          style={{
            fontSize: "24px",
            color: "#5E63B6",
            fontWeight: "700",
            marginBottom: "10px",
          }}
        >
          Connexion à votre assurance
        </div>
        <div style={{ marginBottom: "20px" }}>
          Connectez vous à votre assurance pour récupérer vos contrats.
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "10px",
              marginTop: "20px",
              marginBottom: "40px",
              alignItems: "center",
              justifyContent: "space-around",
              width: "60%",
            }}
          >
            <div
              style={{
                width: "100px",
                height: "100px",
                padding: '10px',
                cursor: "pointer",
                border:
                  selectedInsurance === "Direct Assurance"
                    ? "2px solid #5E63B6"
                    : "none",
              }}
              onClick={() => handleInsuranceSelect("Direct Assurance")}
            >
              <img
                src="Insurances/Direct_Assurance_logo.png"
                alt="Direct Assurance"
                style={{ width: "100%" }}
              />
            </div>
            <div
              style={{
                padding: '10px',
                width: "100px",
                height: "100px",
                cursor: "pointer",
                border:
                  selectedInsurance === "Eurofil"
                    ? "2px solid #5E63B6"
                    : "none",
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
              }}
              onClick={() => handleInsuranceSelect("Eurofil")}
            >
              <img
                src="Insurances/logo_eurofil.jpg"
                alt="Eurofil"
                style={{ width: "100%" }}
              />
            </div>
          </div>
          {selectedInsurance && (
            <div style={{ width: "60%" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <input
                  type="text"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{
                    width: "100%",
                    padding: "10px",
                    backgroundColor: "#E9E5FA",
                    outline: "none",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  marginBottom: "20px",
                }}
              >
                <input
                  type="password"
                  placeholder="Mot de passe"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  style={{
                    width: "100%",
                    padding: "10px",
                    backgroundColor: "#E9E5FA",
                    outline: "none",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <button
                  onClick={handleLogin}
                  style={{
                    padding: "10px 20px",
                    backgroundColor: "#5E63B6",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                  }}
                >
                  Se connecter
                </button>
              </div>
            </div>
          )}
          {responseMessage && (
            <div
              style={{
                marginTop: "20px",
                textAlign: "center",
                color: responseMessage.includes("réussie") ? "green" : "red",
              }}
            >
              {responseMessage}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConnectInsurancesPopup;