import React, { useEffect, useState } from "react";
import { parse, isValid, format } from "date-fns";
import { fr, enUS } from 'date-fns/locale';
import "./BillsImportAndDisplay.css";
import LoadingPopup from "../LoadingPopup/LoadingPopup";
import SearchBar from "./SearchBarBills";
import PostAddIcon from "@mui/icons-material/PostAdd";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const BillsImportAndDisplay = ({
  seeBill,
  domicileId,
  local,
  newListBills,
}) => {
  const [fileToConvert, setFileToConvert] = useState("");
  const [bills, setBills] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredBills, setFilteredBills] = useState(bills);
  const options = [
    "Habitation et Amenagement",
    "Mobilier de jardin",
    "Meubles",
    "Electromenager",
    "Appareils électroniques",
    "Vêtements et accessoires",
    "Bijoux",
    "Œuvres d'art",
    "Voitures",
    "Motos et scooters",
    "Transports légers",
    "Équipements sportifs",
    "Matériel de camping",
    "Instruments de musique",
    "Matériel de photographie",
    "Smartphones",
    "Tablettes",
    "Ordinateurs portables",
    "Lunettes",
    "Équipements médicaux personnels",
  ];
  const typeToSvgMap = {
    "Habitation et Amenagement": "House.svg",
    "Mobilier de jardin": "Barbeuc.svg",
    "Meubles": "Sofa.svg",
    "Electromenager": "Wash.svg",
    "Appareils électroniques": "Tv.svg",
    "Vêtements et accessoires": "Hanger.svg",
    "Bijoux": "Diamond.svg",
    "Œuvres d'art": "Pencil.svg",
    "Voitures": "Car.svg",
    "Motos et scooters": "Moto.svg",
    "Transports légers": "Bike.svg",
    "Équipements sportifs": "Football.svg",
    "Matériel de camping": "Tent.svg",
    "Instruments de musique": "Music.svg",
    "Matériel de photographie": "Camera.svg",
    "Smartphones": "Phone.svg",
    "Tablettes": "Tablet.svg",
    "Ordinateurs portables": "Laptop.svg",
    "Lunettes": "Glasses.svg",
    "Équipements médicaux personnels": "Medical.svg",
  };
  const BillTypeIcon = ({ type }) => {
    const svgFileName = typeToSvgMap[type] || "receip.svg"; // default.svg si le type n'est pas trouvé
    return (
      <img
        src={`/TypesBills/${svgFileName}`}
        alt={type}
        className="bill-type-icon"
        style={{
          width: "90%",
          height: "90%",
          objectFit: "cover",
          objectPosition: "center",
        }}
      />
    );
  };
  const handleFilter = (filtered) => {
    setFilteredBills(filtered);
  };

  const fetchData = async () => {
    let result = await fetch("https://api.coverage-eip.com/bills", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    result = await result.json();
    let filteredBills = result.filter((bill) => bill.domicileId === domicileId);
    setBills(filteredBills);
  };

  useEffect(() => {
    fetchData();
  }, [domicileId]);

  const handleFileSelect = async (e) => {
    const file = e.target.files[0];
    const acceptedTypes = ["application/pdf", "image/jpeg", "image/png"];
    if (!acceptedTypes.includes(file.type)) {
      alert("Veuillez sélectionner un fichier PDF, JPG ou PNG.");
      return;
    }

    const base64 = await convertToBase64(file);
    if (base64) {
      setFileToConvert(base64);
      analyzeBill(base64);
    }
  };

  const analyzeBill = async (base64) => {
    setLoading(true);
    let result = await fetch("https://api.coverage-eip.com/bills/analyze", {
      method: "POST",
      body: JSON.stringify({ data: base64 }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    if (result.status === 200) {
      result = await result.json();
      addBill(result, base64);
      setLoading(false);
    } else {
      setLoading(false);
      alert("Echec de l'importation de la facture");
    }
  };

  const convertToISODate = (dateStr) => {
    const dateFormats = [
      "dd.MM.yyyy", // Format: 31.12.2020
      "yyyy-MM-dd", // Format: 2020-12-31
      "MM/dd/yyyy", // Format: 12/31/2020
      "yyyy/MM/dd", // Format: 2020/12/31
      "dd MMMM yyyy", // Format: 14 août 2023
    ];
  
    const locales = [fr, enUS]; // Support for French and English
  
    console.log(dateStr);
  
    for (let formatStr of dateFormats) {
      for (let locale of locales) {
        const parsedDate = parse(dateStr, formatStr, new Date(), { locale });
        console.log(parsedDate);
        if (isValid(parsedDate)) {
          return format(parsedDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
        }
      }
    }
  
    throw new Error("Invalid date format");
  };

  const addBill = async (data, base64) => {
    const formattedDate = convertToISODate(data.billDate);

    let result = await fetch("https://api.coverage-eip.com/bills/", {
      method: "POST",
      body: JSON.stringify({
        domicileId: domicileId,
        billNumber: data.billNumber,
        billDate: formattedDate,
        totalAfterTax: data.totalAfterTax,
        currency: data.currency,
        products: data.products.map((product) => ({
          name: product.name,
          quantity: product.quantity,
          priceAfterTax: product.priceAfterTax,
          currency: product.currency,
        })),
        data: base64,
        tags: data.tags,
        storeName: data.storeName,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    if (result.status === 200) {
      window.location.reload();
    } else {
      alert("Echec de l'importation de la facture");
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleDeleteBill = async (billId) => {
    try {
      const response = await fetch(
        `https://api.coverage-eip.com/bills/${billId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (response.ok) {
        alert("Facture supprimée avec succès.");
        window.location.reload();
      } else {
        alert(`Erreur lors de la suppression: ${response.status}`);
      }
    } catch (error) {
      alert(`Erreur lors de la suppression: ${error.message}`);
    }
  };

  const openFileExplorer = () => {
    document.getElementById("billsInput").click();
  };

  return (
    <div className="container-global-bill-import">
      <div className="container-bill-import">
        <div className="header-bill-import">
          {loading && <LoadingPopup isBills={true}/>}                                          
          <div className="container-searchbar-input-btn">
            <SearchBar
              bills={bills}
              options={options}
              onFilter={handleFilter}
            />
            <div className="btn-import-file-local" onClick={openFileExplorer}>
              <PostAddIcon style={{ color: "white" }} />
              Importer une facture
            </div>
          </div>
          <input
            type="file"
            id="billsInput"
            style={{ display: "none" }}
            accept=".pdf,.jpg,.png"
            onChange={handleFileSelect}
          />
        </div>
        <div id="bills-display-container">
          {filteredBills.map((bill, index) => (
            <div className="bills-elem margin-bills-elem" key={index}>
              <div
                className="picto-container"
                style={{
                  width: "35%",
                  height: "calc(100% - 10px)",
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: 'center',
                  alignItems:'center',
                  border: "5px solid #5E63B6",
                  borderRadius: "20px",
                  marginRight: "10px",
                }}
              >
                {/* <BillTypeIcon type={"Meubles"} /> */}
                <BillTypeIcon type={bill.tags[0]} />
              </div>
              <div className="container-data-card-bill">
                <div>
                  <div className="name-bill-import" style={{marginBottom: "15px"}}>{bill.storeName}</div>
                  <div className="buy-bill-import">
                    <span>Achat :</span> Date:{" "}
                    {new Date(bill.billDate).toLocaleDateString("fr-FR")}
                  </div>
                  <div className="types-bill-import">
                    <span>Types :</span> {bill.tags[0]}
                  </div>
                  <div className="types-bill-import">
                    <span>Prix :</span> {bill.totalAfterTax} {bill.currency}
                  </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    className="button-update-bill"
                    onClick={() => seeBill(bill, filteredBills)}
                  >
                    Consulter
                    <ArrowForwardIcon style={{ color: "white", marginLeft: '10px' }} />
                  </div>
                </div>
              </div>
              {/* <div className="import-bills-container">
                <img
                  src="receip.svg"
                  className="bill-illustration"
                  alt="Bill"
                />
                <div className="bills-price-elem">
                  {bill.totalAfterTax} {bill.currency}
                </div>
              </div>
              <div className="container-expiration-name">
                <div className="name-bill-import">{bill.storeName}</div>
                <div className="buy-bill-import">
                  <span>Achat :</span> Date:{" "}
                  {new Date(bill.billDate).toLocaleDateString("fr-FR")}
                </div>
                <div className="types-bill-import">
                  <span>Types :</span> {bill.tags.join(", ")}
                </div>
                <div className="buttons-bill-elem-container">
                  <div
                    className="button-update-bill"
                    onClick={() => seeBill(bill)}
                  >
                    Consulter
                    <img src="right-arrow-purple.svg" alt="Arrow" />
                  </div>
                  <div
                    className="button-delete-bill"
                    onClick={() => handleDeleteBill(bill.id)}
                  >
                    Supprimer
                    <img src="red-bin.svg" alt="Bin" />
                  </div>
                </div>
              </div> */}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BillsImportAndDisplay;
