import React, { useState, useEffect, useRef } from "react";
import "./Profil.css";

function Profil(props) {
  const [profileData, setProfileData] = useState({
    lastname: "",
    firstname: "",
    email: "",
    phone: "",
    avatar: "",
    newlastname: "",
    newfirstname: "",
    newemail: "",
    newphone: "",
    newpassword: "",
    newpasswordconfirm: "",
    newavatar: "",
  });

  const [editProfile, setEditProfile] = useState(false);
  const [numbers, setNumbers] = useState({
    numberContract: "0",
    numberLocaux: "0",
    numberbills: "0",
  });

  const [imagePreview, setImagePreview] = useState(null);

  const refs = {
    firstnameRef: useRef(null),
    lastnameRef: useRef(null),
    telRef: useRef(null),
    emailRef: useRef(null),
    passwordRef: useRef(null),
    passwordconfirmRef: useRef(null),
  };

  useEffect(() => {
    getProfile();
    getInsurances();
    getBills();
    getLocaux();
  }, []);

  const getProfile = async () => {
    let result = await fetch("https://api.coverage-eip.com/account", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    if (result.status === 200) {
      result = result.json().then((data) => {
        setProfileData({
          ...profileData,
          lastname: data.lastName,
          firstname: data.firstName,
          email: data.email,
          phone: data.phoneNumber,
          avatar: data.profilPicture,
          newlastname: data.lastName,
          newfirstname: data.firstName,
          newemail: data.email,
          newphone: data.phoneNumber,
        });
      });
    } else {
      alert(result.msg);
    }
  };

  const deleteAccount = async () => {
    let result = await fetch("https://api.coverage-eip.com/account/", {
      method: "delete",
      body: JSON.stringify({}),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    if (result.status === 200) {
      localStorage.clear();
      window.location = "/";
    } else {
      alert(result.statusText);
    }
  };

  const updateProfile = async () => {
    if (
      profileData.newpassword &&
      profileData.newpassword !== profileData.newpasswordconfirm
    ) {
      alert("Les mots de passe ne correspondent pas.");
      return;
    }
    if (
      profileData.newpassword &&
      !profileData.newpassword.match(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,12}$/g
      )
    ) {
      alert(
        "Le mot de passe doit contenir une majuscule, une minuscule, un chiffre, un caractère spécial et 8 caractères au minimum."
      );
      return;
    }

    const updatedProfile = {
      firstName: profileData.newfirstname,
      lastName: profileData.newlastname,
      email: profileData.newemail,
      phoneNumber: profileData.newphone,
      ...(profileData.newpassword && { password: profileData.newpassword }),
    };

    if (profileData.newavatar) {
      updatedProfile.profilPicture = profileData.newavatar;
    }

    let result = await fetch("https://api.coverage-eip.com/account/", {
      method: "put",
      body: JSON.stringify(updatedProfile),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    if (result.status === 200) {
      window.location.reload();
      alert("Vos informations de profil ont bien été modifiées");
    } else {
      alert(result.msg);
      window.location.reload();
    }
  };

  const getLocaux = async () => {
    let result = await fetch("https://api.coverage-eip.com/domicile/", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    if (result.status === 200) {
      result.json().then((data) => {
        setNumbers((prev) => ({ ...prev, numberLocaux: data.length }));
      });
    } else {
      console.error("Failed to fetch data:", result.status);
    }
  };

  const getBills = async () => {
    let result = await fetch("https://api.coverage-eip.com/Bills/", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    if (result.status === 200) {
      result.json().then((data) => {
        setNumbers((prev) => ({ ...prev, numberbills: data.length }));
      });
    } else {
      console.error("Failed to fetch data:", result.status);
    }
  };

  const getInsurances = async () => {
    let result = await fetch(
      "https://api.coverage-eip.com/contracts/allContracts",
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );

    if (result.status === 200) {
      result.json().then((data) => {
        setNumbers((prev) => ({ ...prev, numberContract: data.length }));
      });
    } else {
      console.error("Failed to fetch data:", result.status);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prev) => ({ ...prev, [name]: value }));
    if (!editProfile) setEditProfile(true);
  };

  const handleCancel = () => {
    setProfileData((prev) => ({
      ...prev,
      newlastname: prev.lastname,
      newfirstname: prev.firstname,
      newemail: prev.email,
      newphone: prev.phone,
      newpassword: "",
      newpasswordconfirm: "",
      newavatar: "",
    }));
    setImagePreview(null);
    setEditProfile(false);
  };

  const generateBackgroundColor = (fname, lname) => {
    const initials = `${fname?.charAt(0)}${lname?.charAt(0)}`;
    const colors = [
      "#ff7f0e",
      "#2ca02c",
      "#1f77b4",
      "#d62728",
      "#9467bd",
      "#8c564b",
      "#e377c2",
      "#7f7f7f",
      "#bcbd22",
      "#17becf",
    ];
    const hashCode = initials
      .split("")
      .reduce((acc, char) => char.charCodeAt(0) + acc, 0);
    const colorIndex = hashCode % colors.length;
    return colors[colorIndex];
  };

  const openFileExplorer = () => {
    document.getElementById("profileInput").click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        setProfileData((prev) => ({ ...prev, newavatar: reader.result }));
      };
      reader.readAsDataURL(file);
      if (!editProfile) setEditProfile(true);
    }
  };

  const resetTuto = () => {
    console.log("reset tuto" + localStorage.getItem("needTutorial"));
    localStorage.setItem("needTutorial", true);
    window.location = "/Dashboard";
  };

  return (
    <div className="container-page">
      <div id="title-dashboard">
        <img className="" src="people-white.svg" alt="Icon Dashboard" /> Profil
      </div>
      <div className="profilPage">
        <div className="profilContainer">
          <div className="left-profil-container">
            <input
              type="file"
              id="profileInput"
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <div
              id="profile-pic-profil"
              style={
                imagePreview
                  ? { border: "4px solid #5E63B6" }
                  : { border: "4px solid black" }
              }
              onClick={openFileExplorer}
            >
              {imagePreview && <img src={imagePreview} alt="Profile" />}
              {!imagePreview && profileData.avatar && (
                <img src={profileData.avatar} alt="Profile" />
              )}
              {!imagePreview && !profileData.avatar && (
                <div
                  style={{
                    backgroundColor: generateBackgroundColor(
                      profileData.firstname,
                      profileData.lastname
                    ),
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "white",
                    fontSize: "2vw",
                  }}
                >
                  {profileData.firstname?.charAt(0).toUpperCase()}
                  {profileData.lastname?.charAt(0).toUpperCase()}
                </div>
              )}
            </div>
            <div id="container-infos-profil-numbers">
              <div className="locaux-contrats-container">
                {numbers.numberLocaux}
                <div>Locaux</div>
                <img
                  src="house.svg"
                  alt="house-pic"
                  className="locaux-contrats-pic"
                />
              </div>
              <div className="locaux-contrats-container">
                {numbers.numberContract}
                <div>Contrats</div>
                <img
                  src="file.png"
                  alt="file-pic"
                  className="locaux-contrats-pic"
                />
              </div>
              <div className="locaux-contrats-container">
                {numbers.numberbills}
                <div>Factures</div>
                <img
                  src="file.png"
                  alt="file-pic"
                  className="locaux-contrats-pic"
                />
              </div>
            </div>
            <div id="container-buttons-disconnect-delete">
              <div className="logout-container" style={{marginBottom: '10px'}} onClick={resetTuto}>Revoir le tuto</div>
              <div className="delete-profil-container" onClick={deleteAccount}>
                Supprimer le compte
              </div>
              <div
                className="logout-container"
                onClick={() => {
                  localStorage.clear();
                  window.location = "/";
                }}
              >
                Se déconnecter
                <img src="white-exit.svg" alt="" />
              </div>
            </div>
          </div>
          <div className="vertical-line"></div>
          <div className="right-profil-container">
            <div className="info-profil-container">
              <label className="title-info-profil">Nom :</label>
              <input
                type="text"
                name="newlastname"
                className="info-profil"
                value={profileData.newlastname}
                onChange={handleInputChange}
              />
              <label className="title-info-profil">Prénom :</label>
              <input
                type="text"
                name="newfirstname"
                className="info-profil"
                value={profileData.newfirstname}
                onChange={handleInputChange}
              />
              <label className="title-info-profil">Téléphone :</label>
              <input
                type="text"
                name="newphone"
                className="info-profil"
                value={profileData.newphone}
                onChange={handleInputChange}
              />
              <label className="title-info-profil">Mail :</label>
              <input
                type="email"
                name="newemail"
                className="info-profil"
                value={profileData.newemail}
                onChange={handleInputChange}
              />
              <label className="title-info-profil">Mot de passe :</label>
              <input
                type="password"
                name="newpassword"
                className="info-profil"
                placeholder="**********"
                onChange={handleInputChange}
                ref={refs.passwordRef}
              />
              <label className="title-info-profil">
                Confirmer mot de passe :
              </label>
              <input
                type="password"
                name="newpasswordconfirm"
                className="info-profil"
                placeholder="**********"
                onChange={handleInputChange}
                ref={refs.passwordconfirmRef}
              />
            </div>
            {editProfile && (
              <div className="buttons-container">
                <button className="button-annuler" onClick={handleCancel}>
                  Annuler
                </button>
                <button className="button-appliquer" onClick={updateProfile}>
                  Appliquer
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profil;
