import React from "react";
import "./CardContact.css";

const CardContact = ({ fname, lname, mail, pic }) => {
  const generateBackgroundColor = (fname, lname) => {
    const initials = `${fname.charAt(0)}${lname.charAt(0)}`;
    const colors = [
      "#ff7f0e",
      "#2ca02c",
      "#1f77b4",
      "#d62728",
      "#9467bd",
      "#8c564b",
      "#e377c2",
      "#7f7f7f",
      "#bcbd22",
      "#17becf",
    ];
    const hashCode = initials
      .split("")
      .reduce((acc, char) => char.charCodeAt(0) + acc, 0);
    const colorIndex = hashCode % colors.length;
    return colors[colorIndex];
  };
  const hasProfilePic = pic !== undefined && pic !== null;
  return (
    <div className="card-contact-dashboard-container">
      <div className="container-pic-contact-dashboard">
        {hasProfilePic ? (
          <img src={pic} alt="" />
        ) : (
          <div
            className="template-contact-pic"
            style={{ backgroundColor: generateBackgroundColor(fname, lname) }}
          >
            {fname.charAt(0).toUpperCase()}
            {lname.charAt(0).toUpperCase()}
          </div>
        )}
      </div>
      <div className="container-txt-contact-dashboard">
        <div>
          {fname} {lname}
        </div>
        <span>{mail}</span>
      </div>
    </div>
  );
};

export default CardContact;
