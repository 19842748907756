import { useEffect, useState } from "react";

export default function GetTutoSteps() {
  const [isRunning] = useState(localStorage.getItem("needTutorial"));

  if (!isRunning) {
    return null;
  } else {
    return {
      run: isRunning,
      steps: [
        {
          target: "#bento-dashboard-locals",
          content: (
            <>
              <p>
                <strong>Récapitulatif des locaux</strong>
              </p>
              <p>Vous retrouvez un résumé de vos locaux ici</p>
            </>
          ),
          disableBeacon: true,
          data: { next: "/Home" }, // /Dashboard
        },
        {
          target: "#container-dropdown-list-local",
          content: (
            <>
              <p>
                <strong>gestion des locaux</strong>
              </p>
              <p>
                Retrouvez la liste de tout vos locaux et créez en de nouveau ici !
              </p>
            </>
          ),
          data: { previous: "/Dashboard" }, // /Home
        },
        {
          target: "#add-local-btn",
          content: (
            <>
              <p>
                <strong>Créez des locaux</strong>
              </p>
              <p>
                Créez en de nouveau ici !
              </p>
            </>
          ),
          data: { previous: "/Dashboard" }, // /Home
        },
        {
          target: "#container-infos-local-selected-details",
          content: (
            <>
              <p>
                <strong>Retrouvez les informations sur votre local</strong>
              </p>
              <p></p>
            </>
          ),
          data: { previous: "/Home" }, // /Home
        },
        {
          target: ".container-global-list-bills-contrats",
          content: (
            <>
              <p>
                <strong>Les contrats et facture de votre Local</strong>
              </p>
              <p>
                Retrouvez les factures et contrats de votre domicile ici ! Vous
                pouvez en importer de nouveau a cet endroit
              </p>
            </>
          ),
          data: { next: "/Contract" }, // /Home
        },
        {
          target: "#container-upload-contracts",
          content: (
            <>
              <p>
                <strong>Contrat Personnel</strong>
              </p>
              <p>
                Vous pouvez importer l'ensemble de vos contrats personnel ici !
              </p>
            </>
          ),
          data: { previous: "/Home" }, // /Contract
        },
      ],
      stepIndex: 0,
    };
  }
}
